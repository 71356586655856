import React, { useState } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { useDeveloperData } from '../contextapi/developerContext';
import formatDate from '../utils/formatDate';
import imageToString from '../utils/imageToString';

export default function DeveloperReviewTicket(props) {
    const show = props.showReviewedTicket;
    const setShow = props.setShowReviewedTicket;
    const selectedTicket = props.ticketDetails;

    const { updatedChanges, setUpdatedChanges } = useDeveloperData();
    const [updateReview, setUpdateReview] = useState({
        remark: '',
        devScreenshots: null
    })

    const handleClose = () => {
        setShow(false)
    }


    // const handleImageUpload = async (event) => {
    //     const files = Array.from(event.target.files); // Get the selected files
    //     const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    //     const validFiles = [];

    //     for (let i = 0; i < files.length; i++) {
    //         let file = files[i];
    //         let fileName = file.name;

    //         // Check file size
    //         if (file.size > maxSize) {
    //             alert(`File size exceeds 2MB: ${fileName}. Please select a smaller file.`);
    //             continue; // Skip large file
    //         }
    //         // Add valid file to the list
    //         // validFiles.push(blobFile);
    //         validFiles.push(imageToString(file));
    //     }
    //     if (validFiles.length > 0) {
    //         setUpdateReview({ ...updateReview, images: `'${validFiles}'` })
    //         console.log(updateReview)
    //     } else {
    //         event.target.value = ''; // Clear the file input if no valid files
    //     }
    // };


    const handlePdfUpload = async (event) => {
        const file = event.target.files[0]; // Get the selected file
        let fileName = file.name;
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        // Rename the file to remove double extensions like .jpg.pdf or .jpeg.pdf
        const lastDotIndex = fileName.lastIndexOf('.');
        const extension = fileName.substring(lastDotIndex + 1).toLowerCase();

        if (extension !== 'pdf') {
            // Remove the double extension
            fileName = fileName.substring(0, lastDotIndex);
            const lastDotIndexNew = fileName.lastIndexOf('.');
            const newExtension = fileName.substring(lastDotIndexNew + 1).toLowerCase();
            if (newExtension !== 'pdf') {
                alert('Please select a PDF file.');
                event.target.value = ''; // Clear the file input
                return;
            }
        }

        // Check if file size exceeds 2MB
        if (file && file.size > maxSize) {
            alert('File size exceeds 2MB. Please select a smaller file.');
            event.target.value = null; // Reset the file input
            return;
        }

        try {
            const devFile = await imageToString(file)
            setUpdateReview({ ...updateReview, devScreenshots: devFile })
            console.log(updateReview)
        } catch (error) {
            alert('failed to create string')
        }
    };

    const handleUpdate = () => {
        // Check if there is an existing object with the same ticketId
        const index = updatedChanges.findIndex(item => item.ticketId === selectedTicket.ticketId);

        // If an object with the same ticketId exists, update its values
        if (index !== -1) {
            setUpdatedChanges(prevUpdatedChanges => [
                ...prevUpdatedChanges.slice(0, index), // Keep items before the found object
                {
                    ...prevUpdatedChanges[index],
                    remark: updateReview.remark ? updateReview.remark : '',
                    devScreenshots: updateReview.devScreenshots ? updateReview.devScreenshots : null
                },
                ...prevUpdatedChanges.slice(index + 1)
            ]);
        } else {
            setUpdatedChanges(prevUpdatedChanges => [...prevUpdatedChanges, {
                ticketId: selectedTicket.ticketId,
                remark: updateReview.remark ? updateReview.remark : '',
                devScreenshots: updateReview.devScreenshots ? updateReview.devScreenshots : null
            }]);
            setUpdateReview({
                remark: '',
                devScreenshots: null
            })
        }
        setShow(false);
    }

    const handleChange = (event) => {
        setUpdateReview({ ...updateReview, [event.target.id]: event.target.value })
    }

    return (
        <>
            {selectedTicket ? (
                <Modal size='lg' show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedTicket.ticketId}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Complainant Name</Col>
                            <Col>{selectedTicket.complainantName}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Complainant Role</Col>
                            <Col>{selectedTicket.complainantRole}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Generated On</Col>
                            <Col>{formatDate(selectedTicket.openDate)}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Type</Col>
                            <Col>{selectedTicket.issueCategory}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Sub-type</Col>
                            <Col>{selectedTicket.issueSubcategory}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Summary</Col>
                            <Col>{selectedTicket.issue}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Remark</Col>
                            <Col>
                                <Form.Group as={Col} md="12" controlId="remark" onChange={handleChange}>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Write your remark here... (Optional)"
                                        autoComplete="off"
                                        style={{ height: '20vh' }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Upload Screenshot</Col>
                            <Col>
                                <Form.Group controlId="devScreenshots" onChange={handlePdfUpload}>
                                    <Form.Label>Upload Images of the resolved issue<span style={{ fontSize: '13px' }}> (Only .pdf)</span></Form.Label>
                                    <Form.Control type="file" accept=".pdf" autoComplete="off" />
                                </Form.Group>
                            </Col>
                        </Row> */}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={() => handleUpdate()}>Request Close</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </>
    )
}
