import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export function ErrorMessage({ show, handleClose, errorTitle, errorMessage }) {

    return (
        <>
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errorTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}


export function SuccessMessage({ show, handleClose, successTitle, successMessage, navigateTo }) {
    const navigate = useNavigate()
    return (
        <>
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{successTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{successMessage}</Modal.Body>
                </Modal>
            </div>
        </>
    )
}