import React, { useState } from 'react'
import { useHandlerData } from '../contextapi/handlerContext';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import formatDate from '../utils/formatDate';

export default function HandlerReviewTicket(props) {
    const show = props.showReviewedTicket;
    const setShow = props.setShowReviewedTicket
    const selectedTicket = props.ticketDetails

    const { updatedChanges, setUpdatedChanges } = useHandlerData()
    const [updateReview, setUpdateReview] = useState({
        ticketId: '',
        ticketPriority: '',
        ticketStatus: '',
        remark: ''
    })
    const handleClose = () => {
        setShow(false)
    }

    const handleUpdate = () => {
        // Check if there is an existing object with the same ticketId
        const index = updatedChanges.findIndex(item => item.ticketId === selectedTicket.ticketId);

        // If an object with the same ticketId exists, update its values
        if (index !== -1) {
            setUpdatedChanges(prevUpdatedChanges => [
                ...prevUpdatedChanges.slice(0, index), // Keep items before the found object
                {
                    ...prevUpdatedChanges[index], // Spread existing object
                    ticketPriority: updateReview.ticketPriority,
                    ticketStatus: updateReview.ticketStatus,
                    remark: updateReview.remark ? updateReview.remark : ''
                },
                ...prevUpdatedChanges.slice(index + 1) // Keep items after the found object
            ]);
            alert('Make sure to click Update all Reviews to save it')
        } else {
            // If no object with the same ticketId exists, add a new object to updatedChanges
            setUpdatedChanges(prevUpdatedChanges => [...prevUpdatedChanges, {
                ticketId: selectedTicket.ticketId,
                ticketPriority: updateReview.ticketPriority,
                ticketStatus: updateReview.ticketStatus,
                remark: updateReview.remark ? updateReview.remark : ''
            }]);
            setUpdateReview({
                ticketId: '',
                ticketPriority: '',
                ticketStatus: '',
                remark: ''
            })

        }
        setShow(false);
    }

    const handleChange = (event) => {
        setUpdateReview({ ...updateReview, [event.target.id]: event.target.value })
    }

    const copyRemark = () => {
        setUpdateReview((prevReview) => ({
            ...prevReview,
            remark: selectedTicket.remark,
        }));
    };

    return (
        <>
            {selectedTicket ? (
                <Modal size='lg' show={show} onHide={handleClose}>


                    <Modal.Header closeButton>
                        <Modal.Title>{selectedTicket.ticketId}</Modal.Title>
                    </Modal.Header>


                    <Modal.Body>
                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Complainant Name</Col>
                            <Col>{selectedTicket.complainantName}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Generated On</Col>
                            <Col>{formatDate(selectedTicket.openDate)}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        {selectedTicket.ticketStatus === "PROGRESS" ? (
                            <>
                                <Row className="d-flex justify-content-between p-1">
                                    <Col md={4}>Set Ticket Priority</Col>
                                    <Col>
                                        <Form.Group controlId="ticketPriority">
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="URGENT"
                                                    name="ticketPriority"
                                                    value="URGENT"
                                                    // checked={status === 'PROGRESS'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="HIGH"
                                                    name="ticketPriority"
                                                    value="HIGH"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="NORMAL"
                                                    name="ticketPriority"
                                                    value="NORMAL"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="LOW"
                                                    name="ticketPriority"
                                                    value="LOW"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row><hr className="mt-0 mb-2" />
                            </>
                        ) : null}

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Type</Col>
                            <Col>{selectedTicket.issueCategory}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Sub-type</Col>
                            <Col>{selectedTicket.issueSubcategory}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Summary</Col>
                            <Col>{selectedTicket.issue}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Update Ticket Status</Col>
                            <Col>
                                {selectedTicket.ticketStatus === "REVIEWING" ? (
                                    <>
                                        <Form.Group controlId="ticketStatus">
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="PROGRESS"
                                                    name="ticketStatus"
                                                    value="PROGRESS"
                                                    // checked={status === 'PROGRESS'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="CLOSED"
                                                    name="ticketStatus"
                                                    value="CLOSED"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </>
                                ) : selectedTicket.ticketStatus}

                            </Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Previous Remark</Col>
                            <Col>{selectedTicket.remark}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Remarked By</Col>
                            <Col>{selectedTicket.remarkBy}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        {selectedTicket.ticketStatus === 'REVIEWING' ? (
                            <Row className="d-flex justify-content-between p-1">
                                <Col md={4}>Remark</Col>
                                <Col>
                                    <Button onClick={() => copyRemark()} className='mb-2'>Copy Officer Remark</Button>
                                    <Form.Group as={Col} md="12" controlId="remark" onChange={handleChange}>
                                        <Form.Control
                                            as="textarea"
                                            value={updateReview.remark}
                                            placeholder="Write your remark here... (Optional)"
                                            autoComplete="off"
                                            style={{ height: '20vh' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        ) : null}
                    </Modal.Body>


                    <Modal.Footer>
                        <Button variant='primary' onClick={() => handleUpdate()}>
                            {selectedTicket.ticketStatus === 'REVIEWING' ? 'Add Remark' : 'Set Priority'}
                        </Button>
                    </Modal.Footer>


                </Modal>
            ) : null}
        </>
    )
}
