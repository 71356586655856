import React, { useState } from 'react'
import { useDeveloperData } from '../../../contextapi/developerContext'
import { Row, Col, Table, Button } from 'react-bootstrap'
import DeveloperTicketCountCard from './DeveloperTicketCountCard'
import DeveloperTickets from './DeveloperTickets'
import { multipartApiResponse, postApiResponse } from '../../../utils/apiPromise'
import { ErrorMessage } from '../../../modals/ErrorMessage'

const updateDeveloperTicketApi = process.env.REACT_APP_API_UPDATE_DEVELOPER_TICKETS


export default function DeveloperBody({ sidebarIndex }) {

  const { filter, setFilter, setSearchTerm, setViewSummary, updatedChanges, setUpdatedChanges } = useDeveloperData();

  // For Error Modal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');


  const authToken = sessionStorage.getItem('authToken')

  const handleSubmit = async () => {
    try {
      const resp = await postApiResponse(updateDeveloperTicketApi, {
        arr: updatedChanges
      }, authToken)
      alert(resp.message)
      setUpdatedChanges([])
    } catch (error) {
      setErrorTitle('Failed to insert Logs');
      setErrorMessage(error.response.data.message);
      setShowErrorModal(true);
    }
  }

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };


  if (sidebarIndex === 0) {
    setSearchTerm('')
    setFilter('all')
    setViewSummary(true)
  } else if (sidebarIndex === 1) {
    if (filter === 'reverted') {
      setFilter('all')
    }
    setViewSummary(false)
  } else if (sidebarIndex === 2) {
    setViewSummary(false)
    setFilter('all')
    setSearchTerm('')
  }


  return (
    <>
      {sidebarIndex === 0 ? (
        <>
          <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem' }}>
            <h4>Ticket Statistics</h4><hr />
            <Col md={3}>
              <DeveloperTicketCountCard type={'REVIEWING'} />
            </Col>
            <Col md={3}>
              <DeveloperTicketCountCard type={'PROGRESS'} />
            </Col>
            <Col md={3}>
              <DeveloperTicketCountCard type={'CLOSED'} />
            </Col>
            <Col md={3}>
              <DeveloperTicketCountCard type={'URGENT'} />
            </Col>
          </Row>
          <Row className='m-2 p-4'>
            <h4>Ticket Summary</h4><hr />
            <DeveloperTickets />
          </Row>
        </>
      ) : null}

      {sidebarIndex === 1 ? (
        <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem' }}>
          <DeveloperTickets />
        </Row>
      ) : null}

      {/* Post Update Changes to database */}
      {sidebarIndex === 2 ? (
        <>
          <Row className='m-2'>
            <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
              <Table hover>
                <thead>
                  <tr>
                    <th style={{ width: '120px' }}>Ticket ID</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {updatedChanges.map((ticket, index) => (
                    <tr key={index}>
                      <td>{ticket.ticketId}</td>
                      <td>{ticket.remark ? ticket.remark : "--- Not Updated ---"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className='p-4 bg-light' style={{ borderRadius: '1rem', marginRight: '8px' }}>
              <Button variant='warning' onClick={() => handleSubmit()}>Update Changes</Button>
            </Col>
          </Row>
        </>
      ) : null}

      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
    </>
  )
}
